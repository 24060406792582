import React, { Component } from "react";
import Layout from "../components/site/layout";
import SEO from "../components/site/seo";
import { StaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { isBrowser } from "../services/helper";
import SliderGlide from "../components/site/slider-glide";
import { graphql } from "gatsby";

import * as styles from "../styles/index.module.scss";
import PricePanel from "../components/site/PricePanel";

export default class Amenagements extends Component {
  appearAnimation = [];

  constructor(props) {
    super(props);
    this.state = {
      zoomImage: "",
    };
    this.zoomImage = this.zoomImage.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
  }

  componentDidMount() {
    if (isBrowser()) {
      gsap.registerPlugin(ScrollTrigger);
    }

    this.appearAnimation.forEach((element) => {
      gsap.fromTo(
        element,
        { opacity: 0 },
        { opacity: 1, scrollTrigger: element, duration: 1, ease: "Circ.easeIn" }
      );
    });
  }

  zoomImage(name) {
    this.setState({ zoomImage: name });
  }

  zoomOut(e) {
    this.setState({ zoomImage: "" });
    e.preventDefault();
    e.stopPropagation();
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allFile(filter: { absolutePath: { regex: "/pensions/" } }) {
              edges {
                node {
                  name
                  publicURL
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        `}
        render={(data) => (
          <Layout currentPage="about">
            <SEO
              title="Pensions équines"
              description="Pensions chevaux aux écuries du chalet - centre équestre situé à Annecy Epagny. 
            Découvrez notre pension équine : hébergement sécurisé, soins personnalisés et environnement idéal pour le bien-être de votre cheval. Visites sur rendez-vous"
            />

            <div className="container">
              <div
                className="row small-inversed"
                ref={(div) => {
                  this.appearAnimation.push(div);
                }}
              >
                <div className="col-md-8 image_portrait d-flex">
                  <GatsbyImage
                    image={
                      data.allFile.edges.filter(
                        (e) => e.node.name === "pension_stabulation"
                      )[0].node.childImageSharp.gatsbyImageData
                    }
                    alt="Annecy Epagny écuries stabulation libre"
                  />
                </div>
                <div className="col-md-4 m-auto">
                  <h3>Des chevaux en liberté</h3>
                  <p>
                    Pensions en paddock stabilisé, douche, sellerie, carrière,
                    manège, nébulisateur sur place...
                  </p>
                </div>
              </div>
              <hr />
              <div
                className="row"
                ref={(div) => {
                  this.appearAnimation.push(div);
                }}
              >
                <div className="col-md-4 m-auto">
                  <p>
                    ... avec libre accès à plusieurs boxs et foin à volonté
                    dehors comme dedans.
                  </p>
                </div>
                <div className="col-md-8 rounded image_portrait d-flex">
                  <GatsbyImage
                    image={
                      data.allFile.edges.filter(
                        (e) => e.node.name === "pension_cheval"
                      )[0].node.childImageSharp.gatsbyImageData
                    }
                    alt="Les écuries du Chalet à Annecy en Haute savoie."
                  />
                </div>
              </div>
              <hr />
              <div className="d-flex">
                <div className="m-auto">
                  <h3>Des espaces verts</h3>
                  <p>Ouverture des paddocks en herbe aux beaux jours</p>
                </div>
              </div>
              <div
                className="row small-inversed"
                ref={(div) => {
                  this.appearAnimation.push(div);
                }}
              >
                <div className="col-md-6">
                  <GatsbyImage
                    image={
                      data.allFile.edges.filter(
                        (e) => e.node.name === "pension_pre"
                      )[0].node.childImageSharp.gatsbyImageData
                    }
                    alt="Pensions pré herbes pour chevaux à Annecy Epagny"
                  />
                </div>
                <div className="col-md-6">
                  <GatsbyImage
                    image={
                      data.allFile.edges.filter(
                        (e) => e.node.name === "pension_au_pre"
                      )[0].node.childImageSharp.gatsbyImageData
                    }
                    alt="Pensions équine pré"
                  />
                </div>
              </div>
              <hr />
              <div
                className="row"
                ref={(div) => {
                  this.appearAnimation.push(div);
                }}
              >
                <div className="col-md-4 m-auto">
                  <p>
                    Sortie en manège ou carrière quand les paddocks en herbe ne
                    sont pas pratiquables
                  </p>
                </div>
                <div className="col-md-8 rounded image_portrait d-flex">
                  <GatsbyImage
                    image={
                      data.allFile.edges.filter(
                        (e) => e.node.name === "pension_chevaux_apaisés"
                      )[0].node.childImageSharp.gatsbyImageData
                    }
                    alt="Chevaux au calme, apaisés, détendus dans le sable"
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div
                  className="col-md-8 offset-md-2"
                  ref={(div) => {
                    this.appearAnimation.push(div);
                  }}
                >
                  <PricePanel>
                    <PricePanel.Title>PENSION COMPLETE</PricePanel.Title>
                    <PricePanel.Items>
                      <>Grain compris</>
                      <>Boxes terrasses en troupeau</>
                      <>Paddocks en herbe</>
                      <>Foin à volonté</>
                      <>Accès aux installations</>
                      <>Prestation en plus sur demande (tonte, etc...)</>
                    </PricePanel.Items>
                    <PricePanel.Pricing>480 €/mois</PricePanel.Pricing>
                    <PricePanel.Footer
                      type="pension complète"
                      showModal={(msg = "") =>
                        this.setState({ modalShow: true, modalMessage: msg })
                      }
                    ></PricePanel.Footer>
                  </PricePanel>
                </div>
              </div>
            </div>
          </Layout>
        )}
      />
    );
  }
}
